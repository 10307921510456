import React from "react"

export default function TextContent({ component }) {
  const { content } = component
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <article
            className="update__article"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </div>
  )
}

import React from "react"
import Seo from "../components/seo"
import "../styles/pages/report.scss"
import { graphql, Link } from "gatsby"
import Footer from "../components/Footer"
import Layout from "../components/layout"
import ShareWidget from "../components/ShareWidget"
import DynamicSlider from "../components/DynamicSlider"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ComponentSelector from "../components/ComponentSelector"
import ExtendedReportComponentSelector from "../components/ResourceComponents/ExtendedReportComponentSelector"

export default function ExtendedReport({ data, pageContext, location }) {
  const { slug, title, showFooterForm } = pageContext
  const { host, pathname } = location

  const {
    id,
    metadata,
    introText,
    downloads,
    datePosted,
    thematicTags,
    featuredImage,
    reportSubtitle,
    extendedReport,
    typeOfResource,
    relatedProjects,
    relatedFocusAreas,
    pageBottomComponents,
    headerBackgroundImage,
    basicHeader,
  } = data.extendedReport.nodes[0]

  console.log(basicHeader)

  const description = metadata?.description && metadata?.description;
  const image = metadata?.image && metadata?.image;

  const thematicTagsArray = thematicTags.split(", ")

  const reportTagsArray = [
    {
      tagLabel: "thematicTags",
      tags: [...thematicTagsArray],
    },
    {
      tagLabel: "relatedFocusAreas",
      tags: relatedFocusAreas.map(focusArea => focusArea.title),
    },
  ]

  // = = = = = = = = = = = = =
  // Sliders Props
  const resourcesSliderProps = {
    skip: id,
    limit: 6,
    cardColor: "#18988B",
    cardAlignment: "horizontal",
    headline: "<span>Related</span> Resources",
    link: [
      {
        label: "All resources",
        linkUrl: "/resources",
        isThisAButton: false,
      },
    ],
    relatedProjects: relatedProjects,
    relatedFocusAreas: relatedFocusAreas,
    contentType: [
      {
        contentType: "resource",
      },
    ],
    background: [
      {
        hasBackgroundImage: true,
      },
    ],
  }

  const pressSliderProps = {
    limit: 6,
    cardColor: "#1F1646",
    cardAlignment: "horizontal",
    headline: "In the news",
    relatedResources: [{title: title}],
    link: [],
    contentType: [
      {
        contentType: "press",
      },
    ],
    background: [
      {
        hasBackgroundImage: false,
        backgroundColor: "#E5E4F3",
      },
    ],
  }

  return (
    <>
      <Layout>
        <Seo
          title={title}
          description={description}
          image={image}
        />
        {
          basicHeader ? (
            <div className="policy-toolkit-header hero small">
              <GatsbyImage
                image={getImage(headerBackgroundImage)}
                className="hero__bg"
              />

              <div className="container">
                <div className="row hero__breadcrumb">
                  <div className="col justify-content-start">
                    <Link to="/resources" className="oblique text-white">
                      ‹ Resources
                    </Link>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <span className="tag">{typeOfResource.replace("-", " ")}</span>
                    <h1 className="text-white oblique">{title}</h1>
                  </div>
                </div>
              </div>
            </div>
          ) : 
          (
            <div className="basic-report-page extended" style={headerBackgroundImage ? {backgourndColor: 'black'} : null}>
              <div className="basic-report-page-header hero extended">
                <GatsbyImage
                  image={getImage(headerBackgroundImage)}
                  className="hero__bg"
                />

                <div className="container">
                  <div className="row hero__breadcrumb">
                    <div className="col justify-content-start">
                      <Link to="/resources" className="oblique text-white">
                        ‹ Resources
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-8 offset-lg-1">
                      <div className="hero__text">
                        <span className="tag">{typeOfResource}</span>
                        <h1 className="text-white oblique">{title}</h1>
                        <h5 className="oblique">{reportSubtitle}</h5>
                        <p className="text-white">{datePosted}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="report-download-container">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                      <div className="download-box">
                        <div className="report-img">
                          <GatsbyImage image={getImage(featuredImage)} />
                        </div>
                        <div className="download-text">
                          <div dangerouslySetInnerHTML={{__html: introText}}/>
                          {downloads.length > 0 &&
                            downloads.map(download => (
                              <a
                                className="btn btn-secondary"
                                download
                                href={download.pdf.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {download.buttonText}
                              </a>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              

              {reportTagsArray
                .map(tagsObject => tagsObject.tags)
                .flat()
                .toString().length > 0 && (
                <section className="update__tags">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8 offset-md-2">
                        <div className="content-wrapper">
                          <h5 className="oblique">Tagged</h5>
                          <ul className="tags-list">
                            {reportTagsArray.map(tagObject =>
                              tagObject.tags.map(
                                tag =>
                                  // Generate li just if tag has value
                                  tag && (
                                    <li className="update__tag" key={tag}>
                                      <Link
                                        to={`/resources?${
                                          tagObject.tagLabel
                                        }=${tag.replaceAll(" ", "_")}`}
                                      >
                                        {tag}
                                      </Link>
                                    </li>
                                  )
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
          )
        }

        <div className="report-page-content">
          <ShareWidget title={title} host={host} pathname={pathname} />
          <ExtendedReportComponentSelector components={extendedReport} />
        </div>

        <ComponentSelector components={pageBottomComponents} />

        <DynamicSlider component={resourcesSliderProps} />
        <DynamicSlider component={pressSliderProps} />

        <Footer showFooterForm={showFooterForm} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    extendedReport: allDatoCmsResource(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        typeOfResource
        thematicTags
        title
        introText
        basicHeader
        metadata {
          description
          title
          image {
            url
          }
          twitterCard
        }
        downloads {
          buttonText
          componentId
          pdf {
            url
          }
        }
        reportSubtitle
        datePosted(formatString: "MMMM YYYY")
        headerBackgroundImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        slug
        showFooterForm
        featuredImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        relatedFocusAreas {
          title
        }
        relatedProjects {
          title
        }
        thematicTags
        extendedReport {
          ... on DatoCmsFeatureCta {
            componentId
            hasVideo
            headline
            intro
            videoSource
            videoLink
            featureTag
            featuredImage {
              featuredImage {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
            button {
              label
              linkUrl
            }
            background {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on DatoCmsResourcePageCta {
            componentId
            background {
              backgroundColor
              hasBackgroundImage
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            headline
            subtitleText
            fullWidth
            button {
              linkUrl
              label
            }
          }
          ... on DatoCmsVideoEmbed {
            componentId
            embedCode
            youtubeOrVimeo
            imageOverlay
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          ... on DatoCmsInterferenceMap {
            componentId
            interferenceData
          }
          ... on DatoCmsTextContent {
            content
            componentId
          }
          ... on DatoCmsReportTwoColumnBlock {
            componentId
            blockBackground
            columns {
              columnBgColor
              columnText
              ctaButton {
                linkUrl
                label
              }
              columnImage {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on DatoCmsReportSlider {
            componentId
            headline
            subtitle
            sectionBackground
            slides {
              headline
              introText
              modalContent
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        pageBottomComponents {
          ... on DatoCmsBasicCtaBanner {
            componentId
            headline
            description
            cta {
              label
              linkUrl
            }
            imageAlignment
            background {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            bannerImage {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          ... on DatoCmsFormCtaBanner {
            componentId
            headline
            description
            donationForm
            formHeadline
            formLink
            amountButtons {
              amount
            }
            recurringDonationOptions {
              recurringDonation
            }
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
            rightBackground {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            leftBackground {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on DatoCmsResourcePageCta {
            componentId
            background {
              backgroundColor
              hasBackgroundImage
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            headline
            subtitleText
            fullWidth
            button {
              linkUrl
              label
            }
          }
        }
      }
    }
  }
`

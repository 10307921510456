import React, { useState } from "react"
import "./index.scss"
import SlickSlider from "react-slick"
import ReportSlide from "./ReportSlide"
import ReportSlideModal from "./ReportSlideModal"

export default function ReportSlider({ component }) {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalPost, setModalPost] = useState(null)
  const { sectionBackground, headline, subtitle, slides } = component

  const sliderSettings = {
    dots: true,
    arrows: false,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    infinite: true,
    variableWidth: true,
    swipeToSlide: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  }

  const handleModal = e => {
    setModalOpen(true)
    const filteredPost = slides.filter((slide, index) =>
      e.currentTarget.getAttribute("id")?.includes(index)
    )
    setModalPost(filteredPost[0])
  }

  return (
    <>
      {modalOpen && (
        <ReportSlideModal
          open={modalOpen}
          modalPost={modalPost}
          setModalOpen={setModalOpen}
        />
      )}
      <div className={`report-slider ${sectionBackground}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="oblique">{headline}</h3>
              <h5>{subtitle}</h5>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="reportSliderWrapper">
              <SlickSlider {...sliderSettings}>
                {slides?.map((slide, index) => (
                  <ReportSlide
                    key={slide.headline}
                    slide={slide}
                    index={index}
                    handleModal={handleModal}
                  />
                ))}
              </SlickSlider>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

export default function ReportSlide({ slide, handleModal, index }) {
  const { headline, introText, image, modalContent } = slide
  return (
    <div id={`slide-${index}`} className="report-slide" onClick={e => handleModal(e)}>
      <div className="report-slide--image">
        <GatsbyImage image={getImage(image)} alt={headline} />
      </div>
      <div className="report-slide--content">
        <h4 className="oblique">{headline}</h4>
        <div
          className="report-slide__text"
          dangerouslySetInnerHTML={{ __html: introText }}
        />
        <h5>
          READ MORE
        </h5>
      </div>
    </div>
  )
}
